export default {
  "error_1": "Bad request, something went wrong during the process.",
  "error_2": "Attempt to access without providing identification.",
  "error_3": "Access denied even after authentication.",
  "error_4": "The requested resource was not found.",
  "error_5": "The request method is not supported for the requested resource.",
  "error_6": "The request cannot be processed due to incompatible headers.",
  "error_7": "The request causes the application to not work correctly.",
  "error_8": "The resource referred to by the request is no longer available.",
  "error_9": "Invalid authentication credentials.",
  "error_10": "Request frequency limit exceeded.",
  "error_11": "Internal server error.",
  "error_12": "The requested functionality is not supported.",
  "error_13": "Error on the gateway side.",
  "error_14": "Service is unavailable.",
  "error_15": "The gateway is not responding in the set time frame.",

  "notifications_empty": "No notifications",
  "old_cabinet_link": "If there are problems with the entrance, use {0}",
  "old_cabinet_link_label": "Old personal account",

  "button_activate": "Activate",
  "button_connect": "Connect",
  "button_more": "View More",
  "no_data": "No Data",
  "label_sms": "Via SMS",
  "account_exit": "Log out",
  "button_request": "Submit",
  "coming_soon": "The functionality will be available soon!",
  "error_unknown": "An unexpected error occurred",
  


  "title_home": "Jet - Personal Account",
  "title_login": "Jet - Authorization",
  "title_access": "Jet - Trusted Access",
  "title_loyalty": "Jet - Loyalty Program",
  "title_loyalty_offer": "Jet - Loyalty Program Offer",
  "title_tariffs": "Jet - Tariffs",
  "title_payment": "Jet - Payment",
  "title_profile": "Jet - Profile",
  "title_404": "Jet - Page Not Found",

  "validation": {
    "required": "Field is required",
    "number": "Value must be a number",
    "min": "Value cannot be less than {min} digits",
    "min_phone": "Phone number cannot be less than {min} digits",
    "min_pass": "Password must be at least {min} characters in length",
    "min_sum": "minimum amount - {value}",
    "word_pass": "Password must contain at least one letter",
    "number_pass": "Password must contain at least one numeric character",
    "spec_pass": "Password must contain at least one special character. Example: !@#$%^&",
    "same_pass": "Passwords must match",
    "attempts": "You are trying to log in too often. Please try again later"
  },

  "settings_title": "Account Management",
  "settings_form_sms_title": "Management",
  "settings_form_sms_descr": "Dear subscriber! If you wish, you can timely receive information about the remaining amount of traffic on your balance, and always be aware of the technical works on the website and company's news ",
  "settings_form_sms_accept_txt": "By activating the service, you agree to the",
  "settings_form_sms_accept_link": "terms and conditions",
  "settings_form_sms_disabled": "Temporarily Unavailable",
  
  "form_change_pass_title": "Change Password",
  "form_change_pass_old_label": "Your Password",
  "form_change_pass_new_label": "New Password",
  "form_change_pass_new_confirm_label": "Confirm New Password",
  "form_change_captcha_label": "Enter the captcha from the image",
  "form_change_button": "Change Password",
  

  "form_payment_descr_click": "Enter the amount (minimum payment amount is 1000 sum) and click the \"Proceed to Payment\" button. You will then be redirected to the payment page {0}.",
  "form_payment_descr_payme": "Enter the amount (minimum payment amount is 1000 sum) and click the \"Proceed to Payment\" button. You will then be redirected to the payment page {0}.",
  "form_payment_descr_upay": "Enter the amount (minimum payment amount is 1000 sum) and click the \"Proceed to Payment\" button. You will then be redirected to the payment page {0}.",
  "form_payment_descr_card": "Enter the amount and click the \"Proceed to Payment\" button. You will then be redirected to the payment page.",
  "form_payment_pay_helper": " Enter the amount of the subscription fee",
  "form_payment_pay_button": "Proceed to Payment",

  "login_title": "Log in to your account",
  "login_email": "E-mail",
  "login_name": "Login",
  "login_pass": "Password",
  "login_sign-in": "Sign In",

  "footer_copy_1": "{copy} 2023 LLC “East - Telecom”, {year}.",
  "footer_copy_2": "All rights reserved.",

  "nav_home": "Home",
  "nav_history": "Payment History",
  "nav_tariffs": "Tariffs",
  "nav_payment": "Payment",
  "nav_settings": "Profile",

  "menu_toggle": "Open Main Menu",

  "error_title": "Page Not Found",
  "error_text": "Unfortunately, we could not find the page you are looking for.",
  "error_button": "To Home",

  "alert_close": "Close",
  "alert_success": "Successful!",
  "alert_error": "Error!",

  "contract_reg_number": "Contract №: {reg_number}",
  "contract_reg_date": "from {reg_date}",
  "contract_reg_login": "login: {login}",
  "contract_service_login": "Login",
  "contract_count_label": "Account invoice",
  "contract_count": "UZS {count}",
  "contract_balance_label": "Balance",
  "contract_jet_point_label": "Jet Points",
  "contract_jet_rec_pay": "Recommended Payment",
  "contract_button_label": "Top Up",

  "abonent_label": "Subscriber",
  "abonent_address": "Address",
  
  "page_group_services_title": "Services",
  "page_group_tariff_title": "Tariffs",
  "page_group_jet_tariff_title": "Exclusive tariffs for the 'Top Secret' service",
  "page_group_tariffsTurbo_title": "Order the 'Turbo Button' service",
  "page_group_title_sub": {
    "part_1": "Access only through",
    "part_2": "loyalty program",
    "part_3": "for Jet Points.",
  },

  "page_group_loyalty_title": "Available Loyalty Program",
  "page_group_loyalty_descr": "Collect Jet points and get more from your provider",

  "sidebar_feedback_title": "Having connection issues?",
  "sidebar_feedback_descr": "If you have any connection issues, please do not hesitate to contact us!",
  "sidebar_feedback_button": "Connect with us",

  "card_service_title_inet": "Internet",
  "card_service_status": "Active",
  "card_service_status_off": "Not Active",
  "card_service_abon_pay": "{abon_pay} {currency}/month",
  "card_service_trust_access": "Trust Access",
  "card_service_tariff": "{tariff} from {begin_date}",
  "card_service_control": "Tariff Management",
  
  "card_loyalty_speed": "Speed",
  "card_loyalty_time": "Usage Time",
  "card_loyalty_time_hours": "{time} hours",
  "card_loyalty_jet_cost": "Cost in Jet Points",
  "card_loyalty_status_off": "Not Active",
  "card_loyalty_button": "Activate",
  
  "card_service_sidebar_inet": "Internet",
  "card_service_sidebar_tariff": "Tariff: {tariff}",
  "card_service_sidebar_abon_pay": "{abon_pay} {currency}/month",

  "page_group_service_title": "Change Tariff",

  "card_tariff_name": "Name",
  "card_tariff_cost": "Cost",
  "card_tariff_speed": "Speed",
  "card_tariff_speed_value": "{value} Mbps",
  "card_tariff_cost_uzs_jp": "Cost (UZS / JP)",
  "card_tariff_cost_jp": "JP Cost",

  "page_group_services_empty": "No Connected Services",


  "page_group_history_title": "Details",
  "page_group_history_sort_label": "Range:",
  "history_date": "No Data",
  "history_sum": "Amount",
  "history_oper": "Operation",
  "history_descr": "Description",
  "history_no_data": "No Data",

  "trusted_form_title": "By activating the \"Trusted Access\" service, the subscriber fully agrees to the above terms and conditions of the public offer.",
  "trusted_form_pass_label": "Personal account password",
  "trusted_content_title": "Trust Access",
  "trusted_content_sub_title": "Rules of using the 'Trust Access' service:",
  "trusted_content_txt": "The 'Trust Access' service allows activating Internet access for a day without payment for Internet services.",
  "trusted_content_txt_1": "The service can be used only once on the first day of the month after 02:00 am;",
  "trusted_content_txt_2": "The service is valid from the 1st to the 5th of the month (from the moment of activation);",
  "trusted_content_txt_3": "After activating the service, it is recommended to restart the PPPoE session;",
  "trusted_content_txt_4": "On limited tariff plans, if the subscriber fully uses the prepaid traffic according to the tariff plan, traffic beyond the limit will begin;",
  "trusted_content_txt_5": "To use the service, the subscriber must have a debt of no more than one subscription fee for the previous month;",
  "trusted_content_txt_6": "During the activation of the service, the subscriber gets access to the Internet according to the subscriber's tariff plan.",
  "trusted_content_txt_7": "The moment of purchasing the 'Trust Access' service is considered unconditional acceptance by the subscriber to pay the full subscription fee for the next month;",
  "trusted_content_txt_8": "If the subscriber activates the 'Trust Access' service on the first day of the new month and uses the Internet access, and then applies for termination of services with the company or for activation of the 'Port Reservation' service, the subscription fee for the new month must be paid in full.",

  "modal_turbo_title": "Order an Internet package",
  "modal_tariff_title": "Tariff change",

  "payment_title": "Refill"
}